import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { ChevronDown, ChevronUp, Plus } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

// 模拟数据
const MOCK_PLAYERS = [
  {
    id: 1,
    name: "Player1",
    activationCode: "ABC123",
    lastUpdate: "2024-10-26 14:30",
    isInGame: true,
    isDisabled: false,
  },
  // ... 更多玩家数据
];

const MOCK_CODES = [
  {
    id: 1,
    code: "CODE123",
    activationTime: "2024-10-20 10:00",
    expiryTime: "2024-10-27 10:00",
    isDisabled: false,
  },
  // ... 更多激活码数据
];

// 主页面组件
const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('players');

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-6">
        <div className="mb-6 flex gap-2">
          <Button
            onClick={() => setActiveTab('players')}
            variant={activeTab === 'players' ? 'default' : 'outline'}
            className="flex-1"
          >
            玩家列表
          </Button>
          <Button
            onClick={() => setActiveTab('codes')}
            variant={activeTab === 'codes' ? 'default' : 'outline'}
            className="flex-1"
          >
            激活码管理
          </Button>
        </div>

        {activeTab === 'players' ? <PlayersList /> : <ActivationCodes />}
      </div>
    </div>
  );
};

// 玩家列表组件
const PlayersList = () => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const toggleSort = () => {
    const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newDirection);
    // 这里添加重新请求数据的逻辑
  };

  return (
    <div>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between gap-2">
          <CardTitle>玩家列表</CardTitle>
          <Button
            variant="outline"
            size="sm"
            onClick={toggleSort}
            className="flex items-center gap-1"
          >
            按时间{sortDirection === 'desc' ? '倒序' : '正序'}
            {sortDirection === 'desc' ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
          </Button>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {MOCK_PLAYERS.map((player) => (
              <div key={player.id} className="border rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-medium">{player.name}</h3>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex items-center gap-2">
                          <span className="text-sm text-gray-500">
                            {player.isDisabled ? '已禁用' : '已启用'}
                          </span>
                          <Switch
                            checked={!player.isDisabled}
                            onCheckedChange={() => { }}
                          />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>切换玩家账号状态</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <div className="text-sm text-gray-500 space-y-1">
                  <p>激活码: {player.activationCode}</p>
                  <p>最后更新: {player.lastUpdate}</p>
                  <p>状态: {player.isInGame ? '游戏中' : '离线'}</p>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={5}
            onPageChange={setCurrentPage}
          />
        </CardContent>
      </Card>
    </div>
  );
};

// 激活码管理组件
const ActivationCodes = () => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const itemsPerPage = 10;

  const toggleSort = () => {
    const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newDirection);
    // 这里添加重新请求数据的逻辑
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
            <CardTitle>激活码管理</CardTitle>
            <div className="flex items-center gap-2 self-end sm:self-auto">
              <Button
                variant="outline"
                size="sm"
                onClick={toggleSort}
                className="flex items-center gap-1"
              >
                按到期时间{sortDirection === 'desc' ? '倒序' : '正序'}
                {sortDirection === 'desc' ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
              </Button>
              <Dialog>
                <DialogTrigger asChild>
                  <Button size="sm" className="flex items-center gap-1">
                    <Plus className="h-4 w-4" />
                    添加激活码
                  </Button>
                </DialogTrigger>
                <AddCodeDialog />
              </Dialog>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {MOCK_CODES.map((code) => (
              <div key={code.id} className="border rounded-lg p-4">
                <div className="flex justify-between mb-2">
                  <div className="flex-1">
                    <h3 className="font-medium">{code.code}</h3>
                    <div className="text-sm text-gray-500 space-y-1 mt-2">
                      {code.activationTime ? (
                        <p>激活时间: {code.activationTime}</p>
                      ) : (
                        <p>状态: 未使用</p>
                      )}
                      {code.expiryTime && (
                        <p>到期时间: {code.expiryTime}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 ml-4">
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant="outline" size="sm" className="w-20">修改有效期</Button>
                      </DialogTrigger>
                      <EditCodeDialog code={code} />
                    </Dialog>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="flex items-center gap-2">
                            <span className="text-sm text-gray-500">
                              {code.isDisabled ? '已禁用' : '已启用'}
                            </span>
                            <Switch
                              checked={!code.isDisabled}
                              onCheckedChange={() => { }}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>切换激活码状态</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={5}
            onPageChange={setCurrentPage}
          />
        </CardContent>
      </Card>
    </div>
  );
};

// 添加激活码对话框组件
const AddCodeDialog = () => {
  const [code, setCode] = useState('');
  const [duration, setDuration] = useState('7');

  const handleSubmit = () => {
    // 处理提交逻辑
    console.log('Submit:', { code, duration });
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogHeader>
        <DialogTitle>添加激活码</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 py-4">
        <div className="space-y-2">
          <label className="text-sm font-medium">激活码</label>
          <Input
            placeholder="请输入激活码"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium">有效期</label>
          <Select value={duration} onValueChange={setDuration}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">1天</SelectItem>
              <SelectItem value="7">7天</SelectItem>
              <SelectItem value="30">30天</SelectItem>
              <SelectItem value="90">90天</SelectItem>
              <SelectItem value="365">365天</SelectItem>
              <SelectItem value="permanent">永久</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button className="w-full" onClick={handleSubmit}>
          确认添加
        </Button>
      </div>
    </DialogContent>
  );
};

// 修改激活码对话框组件
const EditCodeDialog = ({ code }) => {
  const [duration, setDuration] = useState('7');

  const handleSubmit = () => {
    // 处理提交逻辑
    console.log('Submit:', { code: code.code, duration });
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogHeader>
        <DialogTitle>修改激活码有效期</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 py-4">
        <div className="space-y-2">
          <label className="text-sm font-medium">激活码</label>
          <p className="text-gray-500">{code.code}</p>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium">新的有效期</label>
          <Select value={duration} onValueChange={setDuration}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">1天</SelectItem>
              <SelectItem value="7">7天</SelectItem>
              <SelectItem value="30">30天</SelectItem>
              <SelectItem value="90">90天</SelectItem>
              <SelectItem value="365">365天</SelectItem>
              <SelectItem value="permanent">永久</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button className="w-full" onClick={handleSubmit}>
          确认修改
        </Button>
      </div>
    </DialogContent>
  );
};

// 分页组件
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex justify-center items-center gap-2 mt-4">
      <Button
        variant="outline"
        size="sm"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        上一页
      </Button>
      <span className="text-sm">
        {currentPage} / {totalPages}
      </span>
      <Button
        variant="outline"
        size="sm"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        下一页
      </Button>
    </div>
  );
};

export default AdminDashboard;